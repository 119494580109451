import React from 'react';
import { InputProps } from '../../../types';
import '../styles.scss';

export const Input = ({
  className,
  onKeyDown,
  type,
  name,
  placeholder,
  onChange,
  value,
  required,
  disabled,
  style,
  min,
  max,
  onBlur,
  maxLength,
}: InputProps): JSX.Element => (
  <input
    required={required}
    type={type}
    name={name}
    style={style}
    disabled={disabled}
    onBlur={onBlur}
    placeholder={placeholder ? placeholder : ''}
    className={className ? `${className} input` : 'input'}
    onChange={(event: React.SyntheticEvent) => onChange(event)}
    onKeyDown={onKeyDown}
    value={value}
    data-testid="input"
    min={min}
    max={max}
    maxLength={maxLength}
    autoComplete="on"
  />
);
